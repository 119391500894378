import React, { Component } from 'react'

class CountryLocation extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }

    render() {



        return (
            <div className="block" >
                <div className="row">
                    <div className="col-lg-12" >

                        <h4>Capital City</h4>
                        {this.props.country.majorurbanareas !== undefined && this.props.country.majorurbanareas.length > 0 && this.props.country.majorurbanareas.filter(x => x.is_capital).length > 0 ?
                            <p>{this.props.country.majorurbanareas.filter(x => x.is_capital)[0].place}</p>
                            : ""}

                        <h4>Location</h4>
                        <p>{this.props.country.location || ""} </p>

                        {/* <h4 > Major Urban Areas </h4>
                        <ul> {this.props.country.majorurbanareas.length > 0 &&
                            this.props.country.majorurbanareas.map(function (area, index) {
                                return (
                                    <li key={index}> {area.place || ""} {area.population !== "" ? `(Population : ${area.population})` : ""} {area.is_capital ? "(Capital)" : ""}</li>
                                )
                            })
                        }
                        </ul> */}

                    </div>

                    <div className="col-lg-12" >
                        <h4>Geo Note</h4>
                        <p> {this.props.country.geonote || ""} </p>

                        <h4>Terrain</h4>
                        <p> {this.props.country.terrain.charAt(0).toUpperCase() + this.props.country.terrain.slice(1) || ""} </p>

                        <>{this.props.country.languages.length > 0 ? 
                            <h4>Spoken Languages </h4>
                        : ""}</>

                        <p> {this.props.country.languages.length > 0 &&
                            this.props.country.languages.map(function (lang, index) {
                                return (
                                    <span key={index} >
                                        {lang.name} {typeof lang.note !== 'undefined' ? "(" + lang.note + ")" : ""}
                                    </span>
                                )
                            })
                        } </p>

                        
                        {this.props.country.nationality.noun || "" ?
                            <><h4>Nationality </h4><h4>Noun</h4>
                                <p> {this.props.country.nationality.noun}</p>
                            </>
                            : ""}

                        {this.props.country.nationality.adjective || "" ?
                            <>
                                <h4>Adjective </h4>
                                <p>{this.props.country.nationality.adjective}</p>
                            </>
                            : ""}

                        {this.props.country.abbreviation || "" ?
                            <>
                                <h4>Abbreviation</h4>
                                <p>{this.props.country.abbreviation}</p>
                            </>
                            : ""}
                    </div >
                </div>


            </div>
        )
    }
}

export default CountryLocation