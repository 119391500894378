import React, { Component } from 'react'
//import { request } from 'https';
//import { runInNewContext } from 'vm';
import ExchangeRate from './ExchangeRate'
import NationalAnthem from './NationalAnthem'
import Weather from './WeatherComponent'
//import Map from './MapComponent'
import CountryBackground from './CountryBackgroundComponent'
import CountryLocation from './CountryLocationComponent'
import Religion from './ReligionComponent'
import UrbanAreas from './UrbanAreas'
import Loading from './LoadingComponant'

//import PlacesOfInterest from './PlacesOfInterest'
//import PlaceOfInterest from './PlaceOfInterest'
//import { BrowserRouter as Router, Link, Route } from 'react-router-dom';

class CountryDetails extends Component {
    constructor(props) {
        super(props)
        this.state = {
            restCountryData: {},
            currencies: [],
            city: "",
            errorMsg: "",
            restcountryIsLoading: true,
            countryData: {},
            isLoading: true
        }
    }

    componentDidMount() {
        console.log("cd did mount")
        if (this.props.countryId) {
            if (!this.state.countryData.hasOwnProperty("id")) {
                this.getCountryData(this.props.countryId)
            }

            if (!this.state.restCountryData.hasOwnProperty("id")) {
                this.getRestCountry(this.props.countryId)
            }
        }

    }

    componentDidUpdate(prevProps, prevState) {

        console.log(`prevprops : ${prevProps.countryId}, props ${this.props.countryId}`)
        console.log(this.state)
        console.log(prevState)

        if (prevProps.countryId !== this.props.countryId) {
            console.log("country 1")

            this.setState({ isLoading: true })
            this.getCountryData(this.props.countryId)
            this.getRestCountry(this.props.countryId)
            this.getCity(prevProps, prevState)
        } else
            // if ((this.state.city !== prevState.city) || this.state.city === "") {
            console.log("country 2")
        this.getCity(prevProps, prevState)
        //}
    }

    getCity = (prevProps, prevState) => {
        let capitalCity = "",
           // urbanareasProps = [],
            urbanareasState = [],
            prevStateUrbanAreas = [],
            prevCountry = "",
            //restcountryCapital = "",
            //prevStateRestCountryCapital = "",
            prevCityState = "";

        //try { urbanareasProps = this.props.country.majorurbanareas || [] } catch{ }
        try { urbanareasState = this.state.countryData.majorurbanareas || [] } catch{ }
        try { prevStateUrbanAreas = prevState.countryData.majorurbanareas || [] } catch{ }
        try { prevCountry = prevProps.countryData.id || "" } catch{ }
        //try { restcountryCapital = this.state.restCountryData.capital || "" } catch{ }
        //try { prevStateRestCountryCapital = prevState.restCountryData.capital || "" } catch{ }
        try { prevCityState = prevState.city || "" } catch{ }

        // if (restcountryCapital !== "" && restcountryCapital !== prevStateRestCountryCapital) {
        //     console.log("get new capital")
        // }

        let needCity = false;
        console.log(`props : ${this.props.countryId}, prevProps : ${prevProps.countryId}, prevCityState : ${prevCityState}, city state : ${this.state.city}`)

        //OnPage first load
        if (prevCountry === "" && this.props.countryId !== undefined && this.state.city === "") {
            console.log("OnPage first load");
            needCity = true;
        }

        //Had previous country and now changed
        if (prevProps.countryId !== "" && this.props.countryId !== prevProps.countryId) {
            needCity = true;
        }

        //If country change

        //If city change from drop down
        // if (this.props.countryId === prevCountry && this.state.city !== prevCityState) {
        //     console.log("If city change from drop down")
        //     needCity = false;
        // }

        if (this.state.countryData.majorurbanareas !== prevStateUrbanAreas) {
            needCity = true;
        }

        

        console.log(`need city : ${needCity}`)

        console.log(urbanareasState)
        console.log(prevStateUrbanAreas)

        //if (needCity && urbanareasState.length > 0 && urbanareasState !== prevStateUrbanAreas) {
        if (needCity && urbanareasState.length > 0) {
            console.log("Has urbanareas from state")


            capitalCity = this.state.countryData.majorurbanareas.filter(x => x.is_capital)[0] !== undefined
                ? this.state.countryData.majorurbanareas.filter(x => x.is_capital)[0].place
                : this.state.countryData.majorurbanareas[0].place
            if (prevCityState !== capitalCity) {
                this.setState({ city: capitalCity })
            }
        }

        console.log(`Country id : ${this.props.countryId}, prev country : ${prevProps.countryId}, state areas : ${urbanareasState.length}`)
        if(urbanareasState.length === 0 && this.state.city !== ""){
            this.setState({city : ""})
        }

    }

    getCountryData = async (country, req, res) => {

        console.log(`get country data : ${country}`)
        try {
            await fetch('/getcountry/' + country)
                .then(res => res.json())
                .then((data) => { data !== undefined ? this.setState({ countryData: data, isloading: false }) : this.setState({ countryData: {}, isloading: false }) })
        }
        catch{
            this.setState({ countryData: {}, isloading: false })
        }
    }

    getRestCountry = async (country, req, res) => {
        if (country === "united_states") {
            country = "united states of america"
        }

        try {
            await fetch('/restcountry/' + encodeURI(country.replace("_", " ")))
                .then(res => res.json())
                .then((data) => {
                    //console.log(`restname : ${data[0].name}`)
                    this.setState({ restCountryData: data[0], currencies: data[0].currencies, errorMsg: "", restcountryIsLoading: false })
                })
        } catch (error) {
            this.setState({ restCountryData: {}, currencies: [], errorMsg: `Unable to get a country with name "${country}"`, restcountryIsLoading: false })
            console.log(`test : ${error}`)
            console.log(error)
            console.log(res)
            console.log(req)
        }
    }

    onPlaceChange = (e) => {
        //console.log(e.target.value)
        this.setState({ city: e.target.value })
    }

    render() {
        //var country = this.props.country;
        var country = this.state.countryData;

        //console.log(country)

        if (typeof country !== 'undefined' && country.hasOwnProperty("id")) {
            return (<div>
                <div className="row">
                    <div className="col-lg-9" >
                        {/* {countryState.name} */}
                        <CountryBackground country={country}
                            restCountryData={this.state.restCountryData}
                            restcountryIsLoading={this.state.restcountryIsLoading}
                            isLoading={this.state.isloading}
                        >
                        </CountryBackground>
                    </div>
                    <div className="col-lg-3" >
                        <ExchangeRate
                            currencies={this.state.currencies}
                            getExchangeRate={this.getExchangeRate}
                            exchangeRateData={this.state.exchangeRate}
                            getAllExchangeRates={this.getAllExchangeRates}
                            exchangeRates={this.state.exchangeRates}
                        >
                        </ExchangeRate>
                    </div>
                </div>
                {/* <PlacesOfInterest city={this.state.city} poiData={this.state.poiData} placeData={this.state.placeData} getGetGooglePlace={this.getGetGooglePlace.bind()}></PlacesOfInterest> */}
                {/* <PlaceOfInterest placeData={this.state.placeData}></PlaceOfInterest> */}
                {/* <p>{this.state.restCountryData.currencies[0].code}</p> */}
                <div className="row" >
                    <div className="col-lg-12" >
                        <Weather
                            city={this.state.city}
                            country={country}
                            onPlaceChange={this.onPlaceChange}
                        >
                        </Weather>
                    </div>
                </div>

                <div className="row" >
                    <div className="col-lg-9" >
                        <CountryLocation country={country}
                            restcountryIsLoading={this.state.restcountryIsLoading}>
                        </CountryLocation>
                    </div>
                    <div className="col-lg-3" >
                        <UrbanAreas urbanareas={country.majorurbanareas}
                            countryname={country.name}
                            population={country.population.total}
                            restcountryIsLoading={this.state.restcountryIsLoading}
                        >
                        </UrbanAreas>
                    </div >
                </div>
                <div className="row">
                    <div className="col-lg-9" >
                        <NationalAnthem country={country} restcountryIsLoading={this.state.restcountryIsLoading}></NationalAnthem>
                    </div>
                    <div className="col-lg-3" >
                        <Religion country={country} restcountryIsLoading={this.state.restcountryIsLoading}></Religion>
                    </div>

                </div>
                {/* <Map country={country}></Map> */}
            </div>
            )
        } else {
            return (
                <div>
                    <div className="row" >
                        <div className="col-lg-12" >
                            <div className="block">
                                <Loading isloading={this.state.isLoading} errormessage="Sorry no information to show"></Loading>
                            </div>
                        </div>
                    </div>
                </div>)
        }
    }
}

export default CountryDetails