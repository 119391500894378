import React, { Component } from 'react'
import Loading from './LoadingComponant'

class CountryBackground extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }

    render() {
        return (
            // <div className="block" >
            //     {this.props.country !== undefined ?
            //         <h4>{this.props.country.name}</h4>
            //         : ""}

            //     {this.props.restCountryData !== undefined && this.props.restCountryData.flag !== undefined && !this.props.restcountryIsLoading ?
            //         <img src={this.props.restCountryData.flag} preserveAspectRatio="xMidYMid meet" width="100" height="50" alt="Flag" />
            //         : ""}

            //     {this.props.country !== undefined ?
            //         <p>{this.props.country.background}</p>
            //         : <Loading isloading={this.props.restcountryIsLoading} errormessage="No background information for this country"></Loading>}
            // </div>

            <div className="block" >
                {this.props.country !== undefined && this.props.country.hasOwnProperty("id") && this.props.restCountryData !== undefined && !this.restcountryIsLoading ?
                    <>
                        <div className="row">
                            <div className="col-md-10"><h3>{this.props.country.name}</h3></div>
                            <div className="col-md-2">
                                {this.props.restCountryData.flag !== undefined? 
                                    <img src={this.props.restCountryData.flag} preserveAspectRatio="xMidYMid meet" width="100" alt="Flag" className="flagimage" />
                                : ""}
                                </div>
                        </div>

                        <p className="mt-4">{this.props.country.background}</p>
                    </>
                    : <Loading isloading={this.props.restcountryIsLoading} errormessage="No background information for this country"></Loading>}
            </div>
        )
    }
}

export default CountryBackground