import React, { Component } from 'react'
import PieChart from './PieChart'

class UrbanAreas extends Component {
    constructor(props) {
        super(props)
        this.state = {
            pieChartData: {}
        }
    }

    componentDidMount() {
        this.getChartData()
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.countryname !== undefined && prevProps.countryname !== this.props.countryname) {
            this.getChartData()
        }
    }

    getChartData = () => {

        if (this.props.urbanareas.length > 0) {


            let chartData = this.props.urbanareas.map(d => d.population !== undefined ? d.population : 0);
            let totalUrbanAreas = chartData.reduce((a, b) => a + b, 0)
            let restOfCountryPop = this.props.population - totalUrbanAreas
            //console.log(chartData)
            //console.log(`Total : ${this.props.population}, Totol urban area : ${totalUrbanAreas}, Rest of the country : ${restOfCountryPop}`)

            chartData = [...chartData, restOfCountryPop]

            let chartLabels = this.props.urbanareas.map(d => d.place.charAt(0).toUpperCase() + d.place.slice(1));
            chartLabels = [...chartLabels, "Other"]
            //console.log(chartData)

            let pieChartData = {
                labels: chartLabels,
                datasets: [{
                    label: "Test label - title",
                    data: chartData,
                    backgroundColor: [
                        // 'rgba(54, 162, 235, 0.6)',
                        // 'rgba(255, 206, 86, 0.6)',
                        // 'rgba(75, 192, 192, 0.6)',
                        // 'rgba(153, 102, 255, 0.6)',
                        // 'rgba(255, 159, 64, 0.6)',
                        // 'rgba(255, 99, 132, 0.6)',
                        // 'rgba(255, 99, 132, 0.6)',
                        ['rgba(101, 187, 166, 1)', 'rgba(101, 187, 166, 0.4)'],
                        ['rgba(108, 185, 189, 1)', 'rgba(108, 185, 189, 0.4)'],
                        ['rgba(128, 153, 186, 1)', 'rgba(128, 153, 186, 0.4)'],
                        ['rgba(143, 140, 189, 1)', 'rgba(143, 140, 189, 0.4)'],
                        ['rgba(159, 119, 184, 1)', 'rgba(159, 119, 184, 0.4)'],
                        ['rgba(166, 103, 160, 1)', 'rgba(166, 103, 160, 0.4)'],
                        ['rgba(181, 94, 120, 1)', 'rgba(181, 94, 120, 0.4)'],
                        ['rgba(197, 77, 94, 1)', 'rgba(197, 77, 94, 0.4)'],
                        ['rgba(162, 76, 80, 1)', 'rgba(162, 76, 80, 0.4)'],
                        ['rgba(199, 123, 74, 1)', 'rgba(199, 123, 74, 0.4)'],
                        ['rgba(200, 139, 35, 1)', 'rgba(200, 139, 35, 0.4)']
                    ]
                }]
            }

            this.setState({ pieChartData: pieChartData })
        }
    }

    //https://www.createwithdata.com/react-chartjs-dashboard/
    render() {
        if (this.props.population !== undefined) {
            return (
                <div className="block" >
                    <h4>Major Urban Areas </h4>
                    <p>Total population : {this.props.population.toLocaleString()}</p>
                    <PieChart piechartdata={this.state.pieChartData} title="Percent of population" color="yellow" legendPosition="bottom"></PieChart>

                    {/* {this.props.country.religions.filter(x => x.note).length > 0 ?
                <h4>Notes</h4>
                : ""} */}
                    <ul> {this.props.urbanareas.length > 0 &&
                        this.props.urbanareas.map(function (reli, index) {
                            if (reli.note) {
                                return (
                                    <li key={index}>
                                        {reli.name} : {typeof reli.note !== 'undefined' ? reli.note : ""}
                                    </li>
                                )
                            } else {
                                return ("")
                            }
                        })
                    } </ul>

                    {this.props.urbanareas.length === 0 || this.props.urbanareas === undefined ? "No information on religions for this country" : ""}

                </div >
            )
        } else {
            return <div></div>
        }

    }
}

export default UrbanAreas