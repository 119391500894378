import React, { Component } from 'react'
import { Doughnut } from 'react-chartjs-2'

class PieChart extends Component {
    constructor(props) {
        super(props)
        this.chartRef = React.createRef();
        this.state = {

        }
    }
    //https://www.youtube.com/watch?v=Ly-9VTXJlnA
    componentDidMount() {

    }

    render() {
        return (
            // <canvas ref={this.chartRef} />
            <div className="doughnutchart">
                <Doughnut
                    data={this.props.piechartdata}
                    options={{
                        title: {
                            display: this.props.title,
                            text: 'Percentage',
                            fontSize: 8
                        },
                        legend: {
                            display: 'Legend',
                            align: 'start',
                            position: this.props.legendPosition

                        },
                        maintainAspectRatio: false
                    }}
                // height="500"
                ></Doughnut>
            </div>
        )
    }
}

export default PieChart