import React, { Component } from 'react'
import SearchForm from './Components/SearchForm'
import Header from './Components/HeaderComponent'
//import HomeComponent from './Components/HomeComponent'
import { debounce } from 'lodash';
//import Weather from './Components/WeatherComponent';
//import factbook from './data/factbook.json';

class Home extends Component {
    constructor(props) {
        super(props)
        this.state = {
            Year: (new Date()).getFullYear(),
            restCountry: {}
        }
        this.getCountries = this.getCountries.bind(this)

    }

    //TODO: Add pie charts https://medium.com/stationfive/how-to-create-a-pie-chart-with-d3-js-and-react-hooks-part-1-81bcd7f39b32

    componentWillMount() {
        this.getCountries();
    }

    filterCountries = debounce(countries => {
        this.getCountries()
    }, 2000)

    getCountries = async (req, res) => {
        try {
            await fetch("/getcountries/")
                .then(res => res.json())
                .then((data) => {
                    this.setState({ searchResults: data })
                })
        } catch (error) {
            console.log(error)
        }
    }

    getRestCountry(countryId) {
        fetch(`https://restcountries.eu/rest/v2/name/${countryId}`)
            .then(res => res.json())
            .then((data) => {
                return data[0]
            })
            .catch(console.log("rest error"))
    }

    render() {
        return (
            <div>
                <div className="header">
                    <div className="row ">
                        <div className="container">
                            <Header></Header>
                        </div>
                    </div>
                </div>
                <div className="container body-content">
                    <SearchForm
                        getCountries={this.getCountries}
                        searchResults={this.state.searchResults}
                    >
                    </SearchForm>

                    <div className="row">
                        <div className="container">
                            <footer>
                                <p>&copy; {this.state.Year} - Michael Fisher</p>
                            </footer>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default Home