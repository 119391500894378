import React, { Component } from 'react'
import Weather from './WeatherComponent'
import TravelNews from './TravelNewsComponent'
//import WorldClocks from './WorldClocksComponent'
import ExchangeRate from './ExchangeRate'
//import { BrowserRouter as Router, Link, Route } from 'react-router-dom';

class HomeComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            currentCity: "",
            currentCountryCode: "",
            currentCountry: "",
            weatherQuery: "",
            longitude: 0,
            latitude: 0,
            timezone: "",
            utc_offset: "",
            currencies: [],
            postal: ""
        }
    }

    componentDidMount() {
        //console.log(" Home C did mount")
        this.getIpLocation();
    }

    componentDidUpdate(prevProps, prevState) {
        //console.log("Home C did update")
        if (prevState.currentCity !== this.state.currentCity) {
            this.getIpLocation()
        }
    }

    getIpLocation = async (req, res) => {
        let url = `https://ipapi.co/json`
        //let url = `https://ipapi.co/99.243.82.222/json` //Canada

        try {
            await fetch(url)
                .then(res => res.json())
                .then(ipLocation => ipLocation !== undefined ?
                    this.setState({
                        currentCity: ipLocation.city,
                        currentCountryCode: ipLocation.country,
                        weatherQuery: `${this.state.currentCity},${this.state.currentCountryCode}`,
                        postal: ipLocation.postal,
                        currencies: [{ code: ipLocation.currency, symbol: "" }]
                    })
                    : this.setState({ currentCity: "", currentCountryCode: "", weatherQuery: "", postal: "", currencies: [] }))
        }
        catch (error) {
            console.log(error)
            this.setState({ currentCity: "" })
        }
    }

    render() {
        return (
            <div>
                {/* <p>                    
                    Our aim at Rough Planet is to offer you easy independent information, advice, and tools for planning travel to destinations worldwide.
                    Find when you can expect the best weather conditions, forecasts and climatology for over 300 destinations around the world.
                </p> */}

                <Weather city={this.state.weatherQuery}></Weather>

                <div className="row">
                    <div className="col-lg-9">
                        <TravelNews></TravelNews>

                    </div>
                    <div className="col-lg-3">
                        <ExchangeRate currencies={this.state.currencies}></ExchangeRate>
                    </div>
                </div>
                {/* <WorldClocks></WorldClocks>
                <p>{this.state.postal}</p> */}
            </div>
        )

    }
}

export default HomeComponent

