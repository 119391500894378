import React, { Component } from 'react'
import Loading from './LoadingComponant'
//import { request } from 'https';
//import { runInNewContext } from 'vm';
//import { BrowserRouter as Router, Link, Route } from 'react-router-dom';

//TODO: issue when changing to a country with a different currency (possible issue with setState)
//TODO: changing from france to antartica still shows paris for weather as there are no urban areas in antartica
//TODO: Get current countries currency code

class ExchangeRate extends Component {
    constructor(props) {
        super(props)
        this.state = {
            exchangeRate: {},
            exchangeRates: [],
            isLoading: true,
            rate: "USD"
        }
    }

    componentDidMount() {
        //console.log("rate did mount")
        this.getAllExchangeRates();

        if (this.props.currencies.length > 0) {

            let rate = this.setDefaultRate(this.props.currencies[0].code)
            this.getExchangeRate(this.props.currencies[0].code, rate)
        } else {
            // console.log("No restcountry data")
        }
    }

    componentWillUnmount() {
        //Cancel all api calls

        //console.log("will unmount")
        this.setState({ isLoading: true })
    }

    setDefaultRate(currency) {
        let rate = ""

        if (currency === "USD") {
            rate = "EUR"
        } else {
            rate = "USD"
        }

        return rate
    }

    // componentDidUpdate(prevProps, prevState) {
    //     //console.log("did update")



    //     if(this.props.currencies.length > 0){
    //         let currencyCode = this.props.currencies[0].code,
    //         rate ="";

    //         rate = this.setDefaultRate(this.props.currencies[0].code)

    //         //Has currencies and no previous values
    //         if (prevProps.currencies.length === 0) {
    //             //this.getExchangeRate(currencyCode, rate)
    //             console.log(`New page currencycode : ${currencyCode}, rate : ${rate}`)
    //         }

    //         //Has currencies and previous prop
    //         if(prevProps.currencies.length > 0 && prevProps.currencies[0].code !== this.props.currencies[0].code){
    //             console.log(`Page change currencycode : ${currencyCode}, rate : ${rate}`)
    //             //this.getExchangeRate(currencyCode, rate)
    //         }
    //     }    
    // }

    componentDidUpdate(prevProps, prevState) {
        //console.log("rates did update")
        //console.log(this.props.currencies.length)
        if (this.props.currencies.length > 0) {
            //console.log(this.props.currencies[0].code)
        }

        if (this.props.currencies !== undefined) {

            //console.log(this.props.currencies)

            let prevCurrencies = [],
                currencies = []

            try { prevCurrencies = prevProps.currencies || [] } catch (error) { }
            try { currencies = this.props.currencies || [] } catch (error) { }

            //console.log(prevCurrencies)
            //console.log(currencies)

            if ((prevCurrencies.length === 0 && currencies.length > 0 && currencies[0].code !== undefined) ||
                (prevCurrencies.length > 0 && currencies.length > 0 && currencies[0].code !== undefined && prevCurrencies[0].code !== currencies[0].code)) {

                this.setState({ isLoading: true })

                if (currencies[0].code === "USD") {
                    this.setState({ rate: "GBP" })
                    this.getExchangeRate(currencies[0].code, "GBP")
                } else {
                    if (prevState.rate === "GBP") {
                        this.setState({ rate: "USD" })
                        this.getExchangeRate(currencies[0].code, "USD")
                    }
                    this.getExchangeRate(currencies[0].code, this.state.rate)
                }
             } 
           //else {
            //     if (this.state.isLoading) {
            //         this.setState({ isLoading: false })
            //     }
            // }

            // if ((prevProps.currencies.length === 0 && this.props.currencies.length > 0 && this.props.currencies[0].code !== undefined) ||
            //     (prevProps.currencies.length > 0 && this.props.currencies[0].code !== undefined && prevProps.currencies[0].code !== this.props.currencies[0].code)) {
            //     //console.log("about to update exchange")
            //     this.setState({ isLoading: true })

            //     if (this.props.currencies[0].code === "USD") {
            //         this.setState({ rate: "GBP" })
            //         this.getExchangeRate(this.props.currencies[0].code, "GBP")
            //     } else {
            //         if (prevState.rate === "GBP") {
            //             this.setState({ rate: "USD" })
            //             this.getExchangeRate(this.props.currencies[0].code, "USD")
            //         }
            //         this.getExchangeRate(this.props.currencies[0].code, this.state.rate)
            //     }
            // }


        }
    }

    getExchangeRate = async (baseRate, rate, req, res) => {
        //console.log(`base : ${baseRate}, rate : ${rate}`)

        try {
            await fetch(`/getexchangerate/${baseRate}/${rate}`)
                //.then(wait => setTimeout((wait) => {},5000))
                //.then(res => res.json(), setTimeout(() => {console.log("wait test")},10000)) 
                .then(res => res.json())
                .then(exchangeRate => exchangeRate !== undefined && exchangeRate.hasOwnProperty("rates") ? this.setState({ exchangeRate: exchangeRate, isLoading: false }) : this.setState({ exchangeRate: {}, isLoading: false }))
        }
        catch (error) {
            console.log(`get exchange rate error`)
            this.setState({ exchangeRate: {}, isLoading: false })
        }
    }

    getAllExchangeRates = async (req, res) => {
        try {
            await fetch(`/getallexchangerates`)
                .then(res => res.json())
                .then(rateCodes => Object.keys(rateCodes.rates))
                .then(rateCodesEuro => [...rateCodesEuro, ['EUR']])
                .then(sortedCodes => sortedCodes.sort())
                .then(exchangeRates => exchangeRates !== undefined ? this.setState({ exchangeRates: exchangeRates }) : this.setState({ exchangeRates: [] }))
        }
        catch (error) {
            //console.log(error)
            this.setState({ exchangeRates: [] })
        }
    }

    setUpExchangeRate({ currencies, prevCode, curCode } = {}) {
        if (!currencies || prevCode !== curCode) {
            //console.log("setup exchange")

            this.getExchangeRate(curCode, this.state.rate)
        }
    }

    selectrate = (e) => {
        this.setState({ rate: e.target.value })
        this.getExchangeRate(this.props.currencies[0].code, e.target.value)
    }

    render() {
        // function createMarkup(markup) {
        //     return { __html: markup };
        // }
        let amount = 0;

        if (this.state.exchangeRate.base !== undefined) {
            amount = Number(Math.round(this.state.exchangeRate.rates[this.state.rate] + 'e2') + 'e-2')
            amount = isNaN(amount) ? "" : amount

            return (

                <div className="block">


                    <div>
                        <h4>Exchange Rate</h4>

                        {this.props.currencies.length > 0 && this.state.exchangeRate.base !== undefined && !this.state.isLoading ?
                            <React.Fragment>
                                <p className="text-center">{this.props.currencies[0].symbol}1 {this.state.exchangeRate.base} = {amount} {this.state.rate}</p>
                                <p className="text-center">Currency : <select onChange={this.selectrate} value={this.state.rate}>
                                    {this.state.exchangeRates !== undefined &&
                                        this.state.exchangeRates.map(function (rate, index) {
                                            return (
                                                <option key={index} value={rate}>{rate}</option>
                                            )
                                        })
                                    }
                                </select>
                                </p>
                                <p className="note text-center">{this.state.exchangeRate.base} to {this.state.rate} on {this.state.exchangeRate.date}</p>
                            </React.Fragment>
                            : <Loading isloading={this.state.isLoading} errormessage="Currency exchange not available for this currency"></Loading>}
                    </div>



                </div>


            )
        } else {
            return (<div className="block"><h4>Exchange Rate</h4><Loading isloading={this.state.isLoading} errormessage="Currency exchange not available for this currency"></Loading></div>)
        }
    }
}
export default ExchangeRate