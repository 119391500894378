import React, { Component } from 'react'
import loadinggif from '../Images/Loading_icon_cropped.gif'

class Loading extends Component {
    constructor(props, context) {
        super(props, context)

        // if (!this.props.name) {
        //     throw new Error('Spinner components must have a name prop.');
        // }

        // if (!this.props.loadingImage && !this.props.children) {
        //     throw new Error('Spinner components must have either a loadingImage prop or children to display.');
        // }

        // this.state = {
        //     show: this.props.hasOwnProperty('show') ? this.props.show : false
        // };

        this.state = {

        }
    }

    render() {
        // let divStyle = { display: 'inline-block' };

        // if (this.state.show) {
        //     const { loadingImage } = this.props;
        //     return (
        //         <div style={divStyle}>
        //             {loadingImage && <img src={loadingImage} />}
        //             {this.props.children}
        //         </div>
        //     );
        // }

        // return (<div style={divStyle}></div>);

        if (this.props.isloading) {
            return (
                // <div>Loading...</div>
                <div className="loadinggif">
                <img src={loadinggif} alt="Loading graphic" width="25"  />
                </div>
            )
        }
        else {
            return (
                <div>{this.props.errormessage}</div>
            )
        }


    }
}

export default Loading