import React, { Component } from 'react'
//import Home from '../home'
//import { BrowserRouter as Router, Link, Route } from 'react-router-dom';

class Header extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }

    render() {
        return (
            <div>
                {/* <Router>
                    <div className="logo" > <Link to={`/`}>React Travel</Link></div>
                    <Route exact path="/" component={Home} />

                </Router> */}
                <div className="logo"><a href="/">React Travel</a></div>
            </div >
        )
    }
}

export default Header