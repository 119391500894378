import React, { Component } from 'react'
import Loading from './LoadingComponant'

class TravelNews extends Component {
    constructor(props) {
        super(props)
        this.state = {
            newsData: [],
            isLoading: true
        }
    }

    componentDidMount() {
        if (this.state.newsData !== []) {
            this.getTravelNews()
        }

    }

    componentDidUpdate(prevProps, prevState) {
        //causes call back loop

        // if (prevState.newsData !== this.state.newsData) {
        //     this.getTravelNews()
        // }
        // if(this.state.newsData !== []){
        //     this.getTravelNews()
        // }
    }

    getTravelNews = async (req, res) => {
        try {
            await fetch("/travelnews")
                .then(res => res.json())
                .then(newsData => newsData !== undefined ? this.setState({ newsData: newsData }) : this.setState({ newsData: [], isLoading: false }))
        }
        catch (error) {
            console.log(error)
            this.setState({ newsData: [], isLoading: false })
        }
    }

    render() {
        function createMarkup(markup) {
            return { __html: markup };
        }
        return (
            <div className="block" >
                <h4>The Independent - News &amp; Advice</h4>


                {this.state.newsData.length > 0 ?
                    <ul className="defaultlist">

                        {this.state.newsData.map(function (article, index) {
                            return (
                                // <li key={index}><a href={article.link} target="_blank" rel="noopener noreferrer">{article.title} - ({article.pubdate})</a></li>
                                <li key={index}><a href={article.link} target="_blank" rel="noopener noreferrer" dangerouslySetInnerHTML={createMarkup(`${article.title} <span class="newsdate">(${article.pubdate})</span>`)} /></li>
                            )
                        })

                        }
                    </ul>
                    : <Loading isloading={this.state.isLoading} errormessage="Sorry no news stories to show"></Loading>}
            </div >
        )
    }
}

export default TravelNews