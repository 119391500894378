import React, { Component } from 'react'
import CountryDetails from './CountryDetails'
import HomeComponent from './HomeComponent'
import { Link, Route } from 'react-router-dom';

class SearchForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            searchResults: [],
            searchQuery: '',
            showResults: false,
            showHomeComp: true,
            restCountryData: {}
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handlelinkClick = this.handlelinkClick.bind(this)
        //this.getRestCountry = this.getRestCountry.bind(this)
    }

    handleChange(e) {
        this.setState({ searchQuery: e.target.value })
        this.setState({ showResults: true })
        this.setState({ showHomeComp: false })
        var countries = this.props.getCountries();
        this.setState({ searchResults: countries })
        //this.props.getRestCountry()
    }

    handleSubmit(e) {
        var countries = this.props.getCountries();
        this.setState({ searchResults: countries })
        e.preventDefault();
    }

    handlelinkClick(e) {
        this.setState({ showResults: false })



        //console.log(e)
        //console.log(`rest country : ${this.state.restCountryData.name}`)
        //this.setState({ restCountryData: restCountryData })

        //e.preventDefault()
    }



    render() {
        let q = this.state.searchQuery

        let filteredResults = [];
        if (typeof this.props.searchResults !== 'undefined') {
            filteredResults = this.props.searchResults.filter(x => x.name.toLowerCase().includes(q.toLowerCase())).slice(0, 15)
        }

        return (

            <div className="row">
                <div className="col-lg-12">

                    <div className="block">

                        <div className="col-lg-12 searchblock">
                            <div className="searchform">
                                <h4>Where would you like to go today?</h4>

                                <form onSubmit={this.handleSubmit}>
                                    <input type="text" placeholder="Search for a country..." onChange={this.handleChange} value={this.state.searchQuery} className="searchinput" />
                                    <button>Search</button>
                                </form>

                                <div className={"searchresults " + (filteredResults.length > 0 && this.state.showResults ? 'showresults' : '')}>
                                    <ul>
                                        {this.state.showResults ?

                                            filteredResults.map(item => {
                                                return (

                                                    <li key={item.id}>
                                                        <Link to={`/CountryDetails/${item.id}`} onClick={this.handlelinkClick}>{item.name}</Link>
                                                    </li>
                                                )
                                            })
                                            : null
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Route exact path="/" render={() => (<HomeComponent></HomeComponent>)} />
                    <Route path="/CountryDetails/:id" render={({ match }) => (
                        <CountryDetails
                            country={filteredResults.find(c => c.id === match.params.id)}
                            restCountryData={this.state.restCountryData}
                            getRestCountry={this.props.getRestCountry}
                            countryId={match.params.id}
                        />
                    )} />

                </div>
            </div>
        )
    }
}

export default SearchForm