import React, { Component } from 'react'
import PieChart from './PieChart'

class Religion extends Component {
    constructor(props) {
        super(props)
        this.state = {
            pieChartData: {}
        }
    }

    componentDidMount() {
        this.getChartData()
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.country !== undefined && prevProps.country.name !== this.props.country.name) {
            this.getChartData()
        }
    }

    getChartData = () => {

        if (this.props.country.religions.length > 0) {

            let chartData = this.props.country.religions.map(d => d.percent !== undefined ? d.percent : 0)
            //console.log(chartData)

            let pieChartData = {
                labels: this.props.country.religions.map(d => d.name.charAt(0).toUpperCase() + d.name.slice(1)),
                datasets: [{
                    label: "Test label - title",
                    data: chartData,
                    backgroundColor: [
                        'rgba(255, 99, 132, 0.6)',
                        'rgba(54, 162, 235, 0.6)',
                        'rgba(255, 206, 86, 0.6)',
                        'rgba(75, 192, 192, 0.6)',
                        'rgba(153, 102, 255, 0.6)',
                        'rgba(255, 159, 64, 0.6)',
                        'rgba(255, 99, 132, 0.6)'
                    ]
                }]
            }

            this.setState({ pieChartData: pieChartData })
        }
    }

    //https://www.createwithdata.com/react-chartjs-dashboard/
    render() {

        if (this.props.country.religions.length > 0) {


            return (
                <div className="block" >
                    <h4>Religions</h4>
                    <PieChart piechartdata={this.state.pieChartData} title="Test Title" color="yellow" legendPosition="bottom"></PieChart>

                    {this.props.country.religions.filter(x => x.note).length > 0 ?
                        <h4>Notes</h4>
                        : ""}
                    <ul> {this.props.country.religions.length > 0 &&
                        this.props.country.religions.map(function (reli, index) {
                            if (reli.note) {
                                return (
                                    <li key={index}>
                                        {reli.name} : {typeof reli.note !== 'undefined' ? reli.note : ""}
                                    </li>
                                )
                            } else {
                                return ("")
                            }
                        })
                    }</ul>

                    {this.props.country.religions.length === 0 || this.props.country.religions === undefined ? "No information on religions for this country" : ""}

                </div >
            )
        } else {
            return <div></div>
        }
    }
}

export default Religion