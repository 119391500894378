import React, { Component } from 'react'

class NationalAnthem extends Component {
    constructor(props) {
        super(props)
        this.state = {
            
        }
    }
    componentDidUpdate() {
        let nationalAnthemUrl = ""
        try{ nationalAnthemUrl = this.props.country.nationalanthem.audio_url || ""}catch{}

        if(nationalAnthemUrl !== "")
        {
            this.refs.audio.load()
        }
    }

    render() {
        //console.log(this.props.country.nationalanthem.audio_url)
        if (this.props.country !== undefined && this.props.country.nationalanthem !== undefined &&
             this.props.country.nationalanthem.audio_url !== undefined && this.props.country.nationalanthem.audio_url !== "") {

            return (
                <div className="block">
                    {this.props.country.nationalanthem !== undefined ?
                        <div>
                            <h4>National Anthem</h4>
                            <p>{this.props.country.nationalanthem.name}</p>
                            <p>{this.props.country.nationalanthem.note}</p>
                            <audio controls ref="audio">
                                <source src={this.props.country.nationalanthem.audio_url} type="audio/mpeg" preload="auto" />

                            </audio>
                        </div>
                        :
                        ""
                    }
                </div >
            )
        } else {
            return <div></div>
        }
    }
}

export default NationalAnthem