import React, { Component } from 'react'
import Loading from './LoadingComponant'

class Weather extends Component {
    constructor(props) {
        super(props)
        this.state = {
            forecastData: {},
            isLoading: true
        }
    }

    componentDidMount() {
        //console.log("weather did mount")
        //console.log(`city : ${this.props.city}`)

        if (this.props.city !== "") {
            this.getWeatherForecast(this.props.city)
        }

        if (this.state.forecastData.hasOwnProperty("country") && this.props.city !== "") {
            this.getWeatherForecast(this.props.city)
        } 
        // else {
        //     //console.log(`forcast length : ${this.state.forecastData.hasOwnProperty("country")}`)
        //     this.setState({ isLoading: false })
        // }
    }

    componentDidUpdate(prevProps, prevState) {
        //console.log("weather did update")
        // if (prevProps.countryId !== this.props.countryId) {
        //console.log("1")
        //     this.getWeatherForecast(this.props.city)
        // }

        //console.log(`city update weather : ${this.props.city}`)

        if (prevProps.city !== this.props.city) {
            //console.log("2")
            if (this.props.city !== "") {
                this.getWeatherForecast(this.props.city)
            }
            // else {
            //     console.log("No city")
            //     this.setState({ isLoading: false })
            // }
        }

        // console.log(`Prevpros : ${prevProps.city} | Props : ${this.props.city}`)

        // if(this.props.city === undefined){
        //     console.log(this.props.country.majorurbanareas[0].place)
        //     this.getWeatherForecast(this.props.country.majorurbanareas[0].place)
        // }
    }

    onPlaceChange = (e) => {
        this.props.onPlaceChange(e)
    }

    getCurrentWeather() {
        //request()
    }

    getWeatherForecast = async (place, req, res) => {
        //console.log(`Weather Capital : ${place}`)
let urbanAreas = [];
        try{urbanAreas = this.props.country.majorurbanareas || []}catch{}

        if (place !== "" && place !== ",") {
            if (this.props.country !== undefined && this.props.country.abbreviation !== "") {
                place = `${place},${this.props.country.abbreviation}`
            }
            // console.log(`Place string : ${place}`)
            try {
                await fetch("/getcityforecast/" + place)
                    .then(res => res.json())
                    //.then(forecastlist => forecastlist.list)
                    //.then(forecastData => forecastData.length > 0 ? this.setState({forecastData:forecastData, msg :""}) : this.setState({forecastData:forecastData, msg :"Sorry no weather"}))
                    //.then(forecastData => forecastData.length > 0 ? forecastData => this.setState({ forecastData: forecastData, msg :"" }, function () { console.log(forecastData) }) :  this.setState({forecastData:forecastData, msg :"Sorry no weather"}))
                    .then(forecastData => forecastData !== undefined ? this.setState({ forecastData: forecastData, isLoading: false }) : this.setState({ forecastData: [], isLoading: true }))
            }
            catch (error) {
                console.log(error)
                this.setState({ forecastData: [], isLoading: false })
            }
        }
        else if(urbanAreas.length > 0 && this.props.city === ""){
            console.log("bad place")
            this.setState({ forecastData: [], isLoading: false })
        }
    }

    render() {

        //let forecasts = this.state.forecastData.hasOwnProperty("list") !== false ? this.state.forecastData.list : [];
        let hasForecastData = this.props.city !== "" && this.state.forecastData.hasOwnProperty("list")

        return (
            <div className="block">
                {hasForecastData ?
                    <div>
                        <h4>5 day / 3 hour weather forecast for {this.state.forecastData.city.name}, {this.state.forecastData.city.country}</h4>
                        {this.props.onPlaceChange !== undefined && this.props.country.majorurbanareas.length > 1 ?
                            <select onChange={this.props.onPlaceChange} value={this.props.city}>
                                {this.props.country.majorurbanareas.length !== undefined &&
                                    this.props.country.majorurbanareas.map(function (area, index) {
                                        return (
                                            <option key={index} value={area.place}>{area.place}</option>
                                        )
                                    })
                                }
                            </select>
                            : ""}
                    </div>
                    : <h4>5 day / 3 hour weather forecast</h4>
                }

                <div id="weatherforecast">
                    {hasForecastData ? this.state.forecastData.list.map((item, index) => {
                        var imageUrl = `https://openweathermap.org/img/wn/${item.weather[0].icon}@2x.png`

                        return (
                            <div className="weatherforecastitem" key={index}>
                                <p>{item.dt_txt}</p>
                                <div className="weatherimg"><img src={imageUrl} alt="weather condition icon" /></div>
                                <ul>
                                    <li>Temp : {Math.round(item.main.temp)}&#8451;</li>
                                    <li>Min Temp : {Math.round(item.main.temp_min)}&#8451;</li>
                                    <li>Max Temp : {Math.round(item.main.temp_max)}&#8451;</li>
                                    <li>{item.weather[0].main}</li>
                                    <li>{item.weather[0].description}</li>

                                    <li>Humidity : {item.main.humidity}&#37;</li>
                                    <li>Wind speed : {Math.round(item.wind.speed)}</li>
                                    <li>Wind direction degrees: {Math.round(item.wind.deg)}</li>
                                    <li>Cloudiness : {item.clouds.all}&#37;</li>
                                </ul>
                            </div>
                        )
                    }) : <Loading isloading={this.state.isLoading} errormessage="Sorry no weather forecast for this location"></Loading>}
                </div>
            </div>
        )
    }
}

export default Weather